<template>
  <a-row :gutter="16">
    <a-col :span="10">
      <div
        class="
          border
          rounded
          p-4
          d-flex
          flex-column
          align-items-center
          flex-wrap
          text-center
        "
      >
        <img
          width="80"
          :src="
            institutionData.logoPreview
              ? institutionData.logoPreview
              : institutionData.logo
          "
          alt=""
        />
        <h3 class="mt-3">{{ institutionData.nama }}</h3>
        <p class="mt-2">{{ institutionData.email }}</p>
        <p class="mt-2">{{ institutionData.no_hp }}</p>
        <p class="mt-2">{{ institutionData.fax }}</p>
        <p v-if="institutionData.alamat" class="mt-2">
          {{ institutionData.alamat.lokasi }}
        </p>
      </div>
      <!-- <div class="card mt-3">
        <div class="card-body mt-1">
          <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
        </div>
      </div> -->
    </a-col>
    <a-col :span="14">
      <div class="border rounded p-4">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Institution Logo">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="institutionData.logoPreview"
                  :src="institutionData.logoPreview"
                  alt="avatar"
                  style="width: 100%"
                />
                <div v-else>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Name">
              <a-input
                size="large"
                class="w-100"
                v-model="institutionData.nama"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Department of Education">
              <a-input
                size="large"
                class="w-100"
                v-model="institutionData.dinas_pendidikan"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution City">
              <a-input
                size="large"
                class="w-100"
                v-model="institutionData.alamat.kota"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Institution Type">
              <a-select
                size="large"
                class="w-100"
                v-model="institutionData.tipe_institusi"
              >
                <a-select-option
                  v-for="item in institutionTypes"
                  :key="item.key"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Institution Email">
              <a-auto-complete
                :data-source="dataSource"
                size="large"
                class="w-100"
                v-model="institutionData.email"
                @change="handleChangeEmail"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Institution Phone">
              <a-input
                size="large"
                class="w-100"
                v-model="institutionData.no_hp"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Institution Fax">
              <a-input
                size="large"
                class="w-100"
                v-model="institutionData.fax"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Address">
              <div class="mb-3" style="height: 400px">
                <location-picker :key="1" @change="changeLocation" />
              </div>
              <a-textarea
                v-if="institutionData.alamat"
                v-model="institutionData.alamat.lokasi"
                :auto-size="{ minRows: 5, maxRows: 8 }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Primary Color">
              <input
                class="w-100"
                type="color"
                id="colorPicker"
                @change="(e) => setPrimaryColor(e.target.value)"
                v-model="institutionData.primary_color"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Video Conference">
              <!-- <a-select
                size="large"
                class="w-100"
                v-model="institutionData.video_conference"
              >
                <a-select-option
                  v-for="item in videoConfList"
                  :key="item.key"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
              <a-radio-group size="large" class="w-100" buttonStyle="solid" v-model="institutionData.video_conference">
                <a-radio-button
                  class="w-50 text-center"
                  v-for="item in videoConfList"
                  :key="item.key"
                  :value="item.key">{{ item.name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Template Report Description">
              <!-- <a-select
                size="large"
                class="w-100"
                v-model="institutionData.template_report_description"
              >
                <a-select-option
                  v-for="item in templateReportDescriptionList"
                  :key="item.key"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
              <a-radio-group size="large" class="w-100" buttonStyle="solid" v-model="institutionData.template_report_description">
                <a-radio-button
                  class="w-50 text-center"
                  v-for="item in templateReportDescriptionList"
                  :key="item.key"
                  :value="item.key">{{ item.name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Multiple Choice Amount">
              <a-select
                size="large"
                class="w-100"
                v-model="institutionData.jumlah_pilihan_ganda"
              >
                <a-select-option
                  v-for="item in multipleChoiceAmountList"
                  :key="item.key"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Presence Setting">
              <!-- <a-select
                size="large"
                class="w-100"
                v-model="institutionData.presence_setting"
              >
                <a-select-option
                  v-for="item in presenceSetting"
                  :key="item.key"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select> -->
              <a-radio-group size="large" class="w-100" buttonStyle="solid" v-model="institutionData.presence_setting">
                <a-radio-button
                  class="w-50 text-center"
                  v-for="item in presenceSetting"
                  :key="item.key"
                  :value="item.key">{{ item.name }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Announcement Setting">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <a-switch v-model="institutionData.is_announcement" size="large" />
                </div>
                <div class="font-size-16">{{ institutionData.is_announcement ? 'Active' : 'Inactive' }}</div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Institution Maximum Presence Hours Setting">
              <a-select
                size="large"
                class="w-100"
                v-model="institutionData.id_batas_absen"
              >
                <a-select-option
                  :value="''"
                >
                  NO MAXIMUM TIME
                </a-select-option>
                <a-select-option
                  v-for="item in modelActive"
                  :key="item.id"
                  :value="item.id"
                  :disabled="item.tipe_jam === 'istirahat'"
                  :class="item.tipe_jam === 'istirahat' ? 'text-center' : ''"
                >
                  {{convertStringHour(item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Parent App School Tuition Payment Feature">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <a-switch v-model="institutionData.fitur_pembayaran_sekolah" size="large" />
                </div>
                <div class="font-size-16">{{ institutionData.fitur_pembayaran_sekolah ? 'Active' : 'Inactive' }}</div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Parent App PPOB Feature">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <a-switch v-model="institutionData.fitur_ppob" size="large" />
                </div>
                <div class="font-size-16">{{ institutionData.fitur_ppob ? 'Active' : 'Inactive' }}</div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Student App Achievement in Gamification Feature">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <a-switch v-model="institutionData.fitur_gamifikasi_penghargaan" size="large" />
                </div>
                <div class="font-size-16">{{ institutionData.fitur_gamifikasi_penghargaan ? 'Active' : 'Inactive' }}</div>
              </div>
            </a-form-item>
          </a-col>
          <a-button
            @click.prevent="updateForm"
            :loading="loadingForm"
            class="float-right"
            type="primary"
            size="large"
            >{{ loadingForm ? "Updating..." : "Update Now" }}</a-button
          >
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import config from '@/config'
import axios from 'axios'
import setPrimaryColor from '@/helpers/setPrimaryColor'
// import Menu from '@/components/app/MenuV2'

const LocationPicker = () => import('@/components/app/shared/LocationPicker')
const key = 'updatable'
const institutionTypes = [
  {
    key: 'normal',
    name: 'Normal School',
  },
  {
    key: 'islam',
    name: 'Islamic School',
  },
  {
    key: 'kristen',
    name: 'Christian School',
  },
  {
    key: 'lain-lain',
    name: 'Other',
  },
]
const multipleChoiceAmountList = [
  // {
  //   key: 3,
  //   name: '3 choices (a, b, c)',
  // },
  {
    key: 4,
    name: '4 choices (a, b, c, d)',
  },
  {
    key: 5,
    name: '5 choices (a, b, c, d, e)',
  },
]
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  components: {
    LocationPicker,
    // Menu,
  },
  data() {
    return {
      institutionTypes,
      dataSource: [],
      loadingForm: false,
      counter: 5,
      institutionData: {
        nama: null,
        kota: null,
        dinas_pendidikan: null,
        logoPreview: null,
        logo: null,
        email: null,
        tipe_institusi: null,
        no_hp: null,
        fax: null,
        alamat: {},
        logoUpload: null,
        primary_color: null,
        video_conference: null,
        template_report_description: null,
        jumlah_pilihan_ganda: null,
        presence_setting: null,
        is_announcement: false,
        id_batas_absen: '',
        fitur_pembayaran_sekolah: false,
        fitur_ppob: false,
        fitur_gamifikasi_penghargaan: false,
      },
      videoConfList: [
        {
          key: 'hasancam',
          name: 'Hasan Cam',
        },
        {
          key: 'zoom',
          name: 'Zoom',
        },
      ],
      templateReportDescriptionList: [
        {
          key: 'by admin',
          name: 'By Admin',
        },
        {
          key: 'by subject teacher',
          name: 'By Subject Teacher',
        },
      ],
      presenceSetting: [
        {
          key: 'by session',
          name: 'By Session',
        },
        {
          key: 'by day',
          name: 'By Day',
        },
      ],
      multipleChoiceAmountList,
      modelActive: [],
      menuActive: 'institution',
      menus: [
        {
          key: 'institution',
          menu: 'Institution',
        },
        {
          key: 'parent',
          menu: 'Parent App',
        },
      ],
    }
  },
  methods: {
    setPrimaryColor,
    handleChangeEmail(value) {
      this.dataSource =
        !value || value.indexOf('@') >= 0
          ? []
          : [`${value}@gmail.com`, `${value}@yahoo.com`]
    },
    changeLocation({ position }) {
      this.institutionData.alamat.latitude = position.lat
      this.institutionData.alamat.longitude = position.lng
    },
    async updateForm() {
      try {
        if (!this.isValidatedForm) {
          return this.$notification.error({
            message: 'Warning',
            description: 'All fields are required',
          })
        }

        const formData = new FormData()
        formData.append('nama', this.institutionData.nama)
        formData.append('dinas_pendidikan', this.institutionData.dinas_pendidikan)
        // formData.append('kota', this.institutionData.kota)
        formData.append('email', this.institutionData.email)
        formData.append('tipe_institusi', this.institutionData.tipe_institusi)
        formData.append('no_hp', this.institutionData.no_hp)
        formData.append('fax', this.institutionData.fax)
        formData.append('alamat', JSON.stringify(this.institutionData.alamat))
        formData.append('logo', this.institutionData.logo)
        formData.append('primary_color', this.institutionData.primary_color)
        formData.append('upload', this.institutionData.logoUpload)
        formData.append('video_conference', this.institutionData.video_conference)
        formData.append('template_report_description', this.institutionData.template_report_description)
        formData.append('jumlah_pilihan_ganda', this.institutionData.jumlah_pilihan_ganda)
        formData.append('presence_setting', this.institutionData.presence_setting)
        formData.append('is_announcement', this.institutionData.is_announcement)
        formData.append('id_batas_absen', this.institutionData.id_batas_absen)
        formData.append('fitur_pembayaran_sekolah', this.institutionData.fitur_pembayaran_sekolah)
        formData.append('fitur_ppob', this.institutionData.fitur_ppob)
        formData.append('fitur_gamifikasi_penghargaan', this.institutionData.fitur_gamifikasi_penghargaan)

        this.loadingForm = true

        const { data: { data: resInstitutionData } } = await axios.post(`${config.apiUrl}/api/institusi`, formData, {
          headers: {
            token: localStorage.token,
          },
        })
        localStorage.removeItem('institusi')
        this.$notification.open({
          key,
          icon: <a-icon type="check-circle" style="color: #52c41a" />,
          message: 'Success',
          description: `Success update institution. App will reload in ${this.counter}s to update the latest changes`,
          duration: 0,
        })
        this.institutionData.logoPreview = null
        if (this.institutionData.id_sekolah) {
          const formDataIntercon = new FormData()
          formDataIntercon.append('nama', this.institutionData.nama)
          formDataIntercon.append('backend_url', config.apiUrl)
          await axios.put(`${config.apiInterconUrl}/api/sekolah/${this.institutionData.id_sekolah}`, {
            headers: {
              token: localStorage.token,
            },
            data: resInstitutionData,
          })
        }
        // this.fetchDataInstitution()
        const countdown = setInterval(() => {
          this.counter--
          this.$notification.open({
            key,
            icon: <a-icon type="check-circle" style="color: #52c41a" />,
            message: 'Success',
            description: `Success update institution. App will reload in ${this.counter}s to update the latest changes`,
            duration: 0,
          })
          if (this.counter === 0) {
            this.loadingForm = false
            location.reload()
            clearInterval(countdown)
          }
        }, 1000)
      } catch (err) {
        console.log(err)
        this.loadingForm = false
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$notification.error({
          message: 'Warning',
          description: 'Only JPG or PNG are allowed',
        })
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$notification.error({
          message: 'Warning',
          description: 'Only file with less 2mb are allowed',
        })
      }

      if (isJpgOrPng && isLt2M) {
        this.institutionData.logoUpload = file
        getBase64(file, imageUrl => {
          this.institutionData.logoPreview = imageUrl
          console.log(this.institutionData)
        })
      }
      return false
    },
    async fetchModelActive() {
      try {
        const data = await this.$store.dispatch('slot/FETCH_MODEL_ACTIVE_SCHEDULE')
        let istirahatCounter = 0
        this.modelActive = data?.model_jadwals.map((model, i) => {
          if (model.tipe_jam === 'istirahat') {
            istirahatCounter++
            return { ...model, urutan: istirahatCounter }
          }
          return { ...model, urutan: i + 1 }
        }) || []
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataInstitution() {
      try {
        if (!this.institutionData) await this.$store.dispatch('master/FETCH_INSTITUSI')
        this.institutionData.nama = this.institusi.nama
        this.institutionData.dinas_pendidikan = this.institusi.dinas_pendidikan
        this.institutionData.logo = this.institusi.logo
        this.institutionData.email = this.institusi.email
        this.institutionData.no_hp = this.institusi.no_hp
        this.institutionData.tipe_institusi = this.institusi.tipe_institusi
        this.institutionData.fax = this.institusi.fax
        this.institutionData.alamat = this.institusi.alamat || {}
        this.institutionData.primary_color = this.institusi.primary_color
        this.institutionData.video_conference = this.institusi.video_conference
        this.institutionData.template_report_description = this.institusi.template_report_description
        this.institutionData.jumlah_pilihan_ganda = this.institusi.jumlah_pilihan_ganda
        this.institutionData.presence_setting = this.institusi.presence_setting
        this.institutionData.is_announcement = this.institusi.is_announcement
        this.institutionData.id_sekolah = this.institusi.id_sekolah
        this.institutionData.id_batas_absen = this.institusi.id_batas_absen || ''
        this.institutionData.fitur_pembayaran_sekolah = Boolean(this.institusi.fitur_pembayaran_sekolah)
        this.institutionData.fitur_ppob = Boolean(this.institusi.fitur_ppob)
        this.institutionData.fitur_gamifikasi_penghargaan = Boolean(this.institusi.fitur_gamifikasi_penghargaan)
      } catch (err) {
        console.log(err)
      }
      // console.log(this.institutionData)
    },
    convertStringHour(item) {
      if (item.tipe_jam === 'istirahat') return `${item.tipe_jam.toUpperCase()} ${item.urutan}`
      else return `${item.tipe_jam.toUpperCase()} ${item.urutan} (${item.jam_mulai.split(':').slice(0, 2).join(':')} - ${item.jam_selesai.split(':').slice(0, 2).join(':')})`
    },
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  mounted() {
    this.fetchModelActive()
    this.fetchDataInstitution()
  },
  computed: {
    isValidatedForm() {
      if (this.institutionData.nama && this.institutionData.tipe_institusi && this.institutionData.email && this.institutionData.no_hp && this.institutionData.alamat) {
        return true
      }
      return false
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 50%;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
